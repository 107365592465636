import BigNumber from 'bignumber.js';
import { Link } from 'gatsby';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import InputNumber from 'rc-input-number';
import axios from 'axios';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import libphonenumber from 'google-libphonenumber';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { DiamondSvg } from '../../static/svg/companySvgCom';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Button, Modal } from 'react-bootstrap';
import { SuccessSvg } from '../../static/svg/invoiceSvgCom';

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

declare let grecaptcha: any;
declare module 'yup' {
  interface StringSchema {
    phone(value: string): this;
  }
}

declare let gtag: any;

interface IFormInputs {
  full_name?: string;
  email_address?: string;
  organisation?: string;
  mobile_number?: string;
  equipment_description?: string;
  supplier_name?: string;
  equipment_value?: string;
}

const EquipPaid = () => {
  // EquipPaid Calculator
  const [equipmentCost, setEquipmentCost] = useState(10000);
  const [period, setPeriod] = useState(6);
  const [financingCharge, setFinancingCharge] = useState(6);

  // EquipPaid Enquiry Form
  const [phone, setPhone] = useState<intlTelInput.Plugin>();
  const [show, setShow] = useState(false);

  Yup.addMethod(Yup.string, 'phone', function (message) {
    return this.test({
      name: 'phone',
      exclusive: false,
      message: message || 'must be a phone number',
      test: async function (value) {
        if (!phone || !value) {
          return false;
        }
        try {
          const area = phone.getSelectedCountryData().iso2.toUpperCase();
          const number = phoneUtil.parse(value, area);
          return phoneUtil.isValidNumber(number);
        } catch (error) {}
        return false;
      },
    });
  });

  const schema = Yup.object().shape({
    full_name: Yup.string().required('Required'),
    email_address: Yup.string().email('Invalid email address').required('Required'),
    mobile_number: Yup.string().required('Required').phone('The phone number is invalid.'),
    organisation: Yup.string().required('Required'),
    equipment_description: Yup.string().required('Required'),
    supplier_name: Yup.string().required('Required'),
    equipment_value: Yup.string().nullable().required('Required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const mobileInput = document.querySelector('#mobile');
    if (mobileInput) {
      const phoneState = intlTelInput(mobileInput, {
        initialCountry: 'auto',
        geoIpLookup: (callback) => {
          axios
            .get<
              undefined,
              {
                data: {
                  country_code: string;
                };
              }
            >('https://ipapi.co/json/', {
              responseType: 'json',
            })
            .then((resp) => {
              if (resp && resp.data && resp.data.country_code) {
                callback(resp.data.country_code.toLowerCase());
              } else callback('sg');
            });
        },
        preferredCountries: ['sg', 'cn', 'hk', 'us'],
      });
      setPhone(phoneState);
    }
  }, []);

  const onSubmit = (data: IFormInputs) => {
    if (!phone) {
      return;
    }
    // 验证

    const area = phone.getSelectedCountryData().dialCode;

    grecaptcha.ready(async () => {
      try {
        const token = await grecaptcha.execute('6Le4KpEaAAAAACJw4liW38ANFo2whC6FjqdWhDdX', {
          action: 'submit',
        });

        const result = await axios.post(
          `${process.env.API_URL}/api/equip-paids`,
          {
            ...data,
            token,
            mobile_number: `${area} ${data.mobile_number}`,
          },
          {
            responseType: 'json',
          },
        );

        if (result && result.data && result.data.status) {
          const contactObj = {
            email: data?.email_address,
            phone: `+${area} ${data.mobile_number}`,
          };
          setShow(true);
          gtag('set', 'user_data', contactObj);
          gtag('event', 'conversion', {
            send_to: 'AW-10939055673/K2UPCPXIroAYELn8kuAo',
          });
        }
      } catch (error) {}

      // 失败弹窗
    });
  };

  // EquipPaid Calculator Styles
  const calculatorProps = {
    railStyle: {
      backgroundColor: '#F9E5DD',
      opacity: 0.3,
      height: 12,
      borderRadius: 30,
    },
    trackStyle: {
      backgroundColor: '#E5010C',
      borderRadius: 30,
      height: 12,
    },
    handleStyle: {
      height: 16,
      width: 16,
      backgroundColor: '#fff',
      opacity: 1,
      borderRadius: '50%',
      boxShadow: '0 1px 5px 0 ragba(0, 0, 0, 0.1)',
      borderColor: '#E8ECEF',
      marginTop: -2,
    },
    dotStyle: {
      border: 'none',
      backgroundColor: 'transparent',
    },
  };

  return (
    <Layout>
      <Seo
        title="Equipment Financing and Leasing Solutions for Singapore SME | EquipPaid+"
        description="EquipPaid+ provides targeted equipment financing and leasing solutions to SME businesses in Singapore, empowering them to access the equipment they need while maintaining healthy cash flow. Explore our competitive rates and streamlined application process to fuel your SME's growth."
      />
      <main className="main-wrapper">
        <HeaderCommon />

        {/* EquipPaid */}
        <CommonBanner>
          <div className="equip-wrap">
            <div className="content-1 equip-mobile">
              <div className="-right">
                <img
                  className="light-mode line1"
                  src="/images/equippaid-img.png"
                  style={{ width: 194 }}
                />
                <img
                  className="light-mode line3"
                  src="/images/equippaid-svg.png"
                  style={{ width: 196 }}
                />
              </div>
              <div className="-left">
                <img className="light-mode" src="/images/equippaid-logo.png" />
                <p className="-title">Equipment Finance Leasing for SMEs in Singapore</p>
                <div className="des-wrap">
                  <p className="-description">
                    {DiamondSvg({})}
                    Conserve cash flow with fixed monthly payments
                  </p>
                  <p className="-description">
                    {DiamondSvg({})}
                    Get 100% financing of your equipment with 0% downpayment
                  </p>
                  <p className="-description">
                    {DiamondSvg({})}
                    Flexible end of lease options
                  </p>
                  <p className="-description">
                    {DiamondSvg({})}
                    Maintain control over your business finances
                  </p>
                  <p className="-description">
                    {DiamondSvg({})}
                    Increase your buying power and take advantage of opportundescriptiones as they
                    arise
                  </p>
                  <img
                    className="light-mode line3"
                    src="/images/equippaid-svg.png"
                    style={{ width: 136, height: 125 }}
                  />
                </div>
                <Link className="banco-btn btn-fill-primary" to="#enquiry">
                  Apply online
                </Link>
              </div>
            </div>
            <div className="content-1">
              <div className="-left">
                <img className="light-mode" src="/images/equippaid-logo.png" />
                <p className="-title">Equipment Finance Leasing for SMEs in Singapore</p>
                <p className="-description">
                  {DiamondSvg({})}
                  Conserve cash flow with fixed monthly payments
                </p>
                <p className="-description">
                  {DiamondSvg({})}
                  Get 100% financing of your equipment with 0% downpayment
                </p>
                <p className="-description">
                  {DiamondSvg({})}
                  Flexible end of lease options
                </p>
                <p className="-description">
                  {DiamondSvg({})}
                  Maintain control over your business finances
                </p>
                <p className="-description">
                  {DiamondSvg({})}
                  Increase your buying power and take advantage of opportunities as they arise
                </p>
                <div className="row">
                  <div className="col-6">
                    <Link className="banco-btn btn-fill-primary" to="#enquiry">
                      Apply online
                    </Link>
                  </div>
                </div>
              </div>
              <div className="-right">
                <img
                  className="light-mode line1"
                  src="/images/equippaid-img.png"
                  style={{ width: 454 }}
                />
                <img
                  className="light-mode line3"
                  src="/images/equippaid-svg.png"
                  style={{ width: 260 }}
                />
              </div>
            </div>

            <p className="-breadcrumb">
              Use our EquipPaid+ Loan Calculator to find out your estimated monthly instalments
            </p>
            <div className="row equip-calculator">
              <div className="col-lg-8 calculator-index">
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <label htmlFor="equipmentCost" className="form-label">
                      Equipment Cost
                    </label>
                    <Slider
                      className="form-range"
                      value={equipmentCost}
                      onChange={(value) =>
                        setEquipmentCost(Array.isArray(value) ? value[0] : value)
                      }
                      min={10000}
                      step={10000}
                      max={1000000}
                      {...calculatorProps}
                    />
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="input-group flex-nowrap">
                      <InputNumber
                        min={10000}
                        max={1000000}
                        step={10000}
                        value={equipmentCost}
                        onChange={(value: number | null) => {
                          if (!value) return;
                          if (value > 1000000) {
                            setEquipmentCost(1000000);
                            return;
                          }
                          if (value < 10000) {
                            setEquipmentCost(10000);
                            return;
                          }
                          setEquipmentCost(value);
                        }}
                        formatter={(value, { userTyping, input }) => {
                          if (userTyping) {
                            return input;
                          }
                          return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                        }}
                      />
                      <span className="input-group-text">$</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <label htmlFor="period" className="form-label">
                      Lease tenure (months)
                    </label>
                    <Slider
                      className="form-range"
                      marks={{ 6: 6, 60: 60 }}
                      min={6}
                      step={1}
                      max={60}
                      value={period}
                      onChange={(value) => setPeriod(Array.isArray(value) ? value[0] : value || 6)}
                      dots={true}
                      {...calculatorProps}
                    />
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="input-group flex-nowrap">
                      <InputNumber
                        min={6}
                        max={60}
                        step={1}
                        value={period}
                        onChange={(value) => {
                          if (value == null) return;
                          if (value > 60) {
                            setPeriod(60);
                            return;
                          }
                          if (value < 6) {
                            setPeriod(6);
                            return;
                          }
                          setPeriod(value);
                        }}
                        formatter={(value, { userTyping, input }) => {
                          if (userTyping) {
                            return input;
                          }
                          return `${value}`;
                        }}
                      />
                      <span className="input-group-text">Months</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 col-md-12">
                    <label htmlFor="financingCharge" className="form-label">
                      Rates per annum
                    </label>
                    <Slider
                      className="form-range"
                      min={6}
                      max={15}
                      step={1}
                      marks={{
                        6: 6,
                        15: 15,
                      }}
                      dots={true}
                      value={financingCharge}
                      onChange={(value) =>
                        setFinancingCharge(Array.isArray(value) ? value[0] : value)
                      }
                      {...calculatorProps}
                    />
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <div className="input-group flex-nowrap">
                      <InputNumber
                        min={6}
                        max={15}
                        step={1}
                        value={financingCharge}
                        onChange={(value) => {
                          if (!value) return;
                          if (value > 15) {
                            setFinancingCharge(15);
                            return;
                          }
                          if (value < 6) {
                            setFinancingCharge(6);
                            return;
                          }
                          setFinancingCharge(value);
                        }}
                        formatter={(value, { userTyping, input }) => {
                          if (userTyping) {
                            return input;
                          }
                          return `${value}`;
                        }}
                      />
                      <span className="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <p className="-description">
                  Disclaimer: Please note that the above calculation is for reference only and
                  actual figures may vary.
                </p>
              </div>
              <div className="col-lg-4 calculator-result">
                <div>
                  <h3>Calculation</h3>
                  <div className="result-row">
                    <div className="item">Monthly Repayment</div>
                    <div className="value">
                      {`$ ${new BigNumber(equipmentCost)
                        .dividedBy(period)
                        .plus(new BigNumber(equipmentCost).times(financingCharge).div(100).div(12))
                        .toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </div>
                  </div>
                  <div className="result-row">
                    <div className="item">Total Repayment</div>
                    <div className="value">
                      <span>
                        {`$ ${new BigNumber(financingCharge)
                          .times(period)
                          .div(12)
                          .div(100)
                          .plus(1)
                          .times(equipmentCost)
                          .toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </span>
                    </div>
                  </div>
                </div>
                <Link className="banco-btn btn-fill-primary" to="#enquiry">
                  Apply online
                </Link>
                <p className="-description">
                  Disclaimer: Please note that the above calculation is for reference only and
                  actual figures may vary.
                </p>
              </div>
            </div>

            <p className="-breadcrumb">Harness upcoming technologies to drive business expansion</p>
            <p className="-sub-breadcrumb">
              Enhance your business's operational efficiency and production capacity by investing in
              modern equipment and machinery, all the while safeguarding the environment for future
              generations. Take advantage of the current opportunity to secure favorable rates on a
              diverse selection of equipment and machinery.
            </p>

            <div className="row content-2 equip-content-harness">
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/equippaid-zero.svg"
                      style={{ width: 92 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Zero upfront downpayment</p>
                    <p className="-description">
                      Get up to <span>$120,000</span> Credit Limit with{' '}
                      <span>just 3 documents</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/equippaid-flexible.svg"
                      style={{ width: 92 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Flexible lease period and monthly payments</p>
                    <p className="-description">
                      Lease period from <span>12 to 36 months</span>. Take ownership of equipment at
                      lease end by paying a nominal sum
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/equippaid-approved.svg"
                      style={{ width: 92 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Get approved within 2 business days</p>
                    <p className="-description">
                      Offer letter issued upon document submission* No Personal Guarantee required
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className="-breadcrumb">How it works</p>
            <div className="row content-3">
              <div className="col">
                <div className="circle">1</div>
                <div>
                  <p className="-title">Apply online</p>
                </div>
              </div>
              <div className="col">
                <div className="circle">2</div>
                <div>
                  <p className="-title">Review and sign your lease offer</p>
                </div>
              </div>
              <div className="col">
                <div className="circle">3</div>
                <div>
                  <p className="-title">
                    Receive your equipment and we'll pay your supplier directly
                  </p>
                </div>
              </div>
            </div>

            <p className="-breadcrumb">Eligibility requirements</p>
            <div className="row content-2 equip-content-requirements">
              <div className="col-lg-5 col-md-12">
                <div className="card">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/equippaid-business-registration.svg"
                      style={{ width: 76 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Business registration</p>
                    <p className="-description">
                      Incorporated in Singapore for <span>at least 1 year</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="card">
                  <div>
                    <img
                      className="light-mode"
                      src="/images/equippaid-business-operations.svg"
                      style={{ width: 76 }}
                    />
                  </div>
                  <div>
                    <p className="-title">Business operations</p>
                    <p className="-description">
                      The equipment to be acquired will <span>only be used in Singapore</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CommonBanner>

        <div id="enquiry" className="section section-equip-enquiry">
          <div className="container">
            <p className="-breadcrumb">Send us your enquiry, we will follow up.</p>
            <div className="row">
              <div className="col-lg-7 col-md-12">
                <div className="card">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <label htmlFor="fullname">Full name</label>
                        <input
                          {...register('full_name')}
                          id="fullname"
                          // name="full_name"
                          placeholder="Enter your full name"
                        />
                        <p>{errors.full_name?.message}</p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label htmlFor="email">Email address</label>
                        <input
                          {...register('email_address')}
                          type="email"
                          id="email"
                          placeholder="Enter your email address"
                        />
                        <p>{errors.email_address?.message}</p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label htmlFor="organisation">Your company</label>
                        <input
                          {...register('organisation')}
                          id="organisation"
                          name="organisation"
                          placeholder="Enter your company"
                        />
                        <p>{errors.organisation?.message}</p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label htmlFor="mobile">Mobile number</label>
                        <input
                          {...register('mobile_number')}
                          id="mobile"
                          name="mobile_number"
                          placeholder="Mobile phone"
                        />
                        <p>{errors.mobile_number?.message}</p>
                      </div>
                      <div className="col-12">
                        <label htmlFor="equipment_description">Equipment Description</label>
                        <input
                          {...register('equipment_description')}
                          name="equipment_description"
                          id="equipment_description"
                          placeholder="Enter equipment description"
                        />
                        <p>{errors.equipment_description?.message}</p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label htmlFor="supplier_name">Equipment Supplier Name</label>
                        <input
                          {...register('supplier_name')}
                          id="supplier_name"
                          name="supplier_name"
                          placeholder="Enter equipment supplier name"
                        />
                        <p>{errors.supplier_name?.message}</p>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <label htmlFor="equipment_value">Total equipment value ($)</label>
                        <input
                          {...register('equipment_value')}
                          type="number"
                          id="equipment_value"
                          name="equipment_value"
                          placeholder="Enter equipment supplier name"
                        />
                        <p>{errors.equipment_value?.message}</p>
                      </div>
                    </div>
                    <button type="submit" className="banco-btn btn-primary">
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section section-equip-invoicepaid-intro">
          <div className="container">
            <p className="-breadcrumb">Other products you may like</p>
            <div className="card">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-logo">
                  <div />
                  <img
                    className="light-mode"
                    src="/images/invoicepaid-logo-w.png"
                    style={{ width: 236 }}
                  />
                </div>
                <div className="col-lg-6 col-md-12">
                  <h4>Smarter way to manage your cash</h4>
                  <p className="-description">
                    A cash management tool designed with industry partners and experts, to help
                    companies better manage their cash, increase business certainty and improve
                    their returns
                  </p>
                  <Link className="banco-btn btn-transparent" to="/invoice-paid">
                    View more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>

      <Modal className="contact-modal" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <button onClick={() => setShow(false)}>
            <img src="/images/close-img.png" alt="" />
          </button>
        </Modal.Header>
        <Modal.Body>
          {SuccessSvg({})}
          <p>We have received your message.</p>
          <p>We’ll get back to you as soon as possible.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShow(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

export default EquipPaid;
